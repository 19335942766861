import { useMobile } from "../../../hooks/useMobile";
import "../CookiesPolicy/CookiesPolicy.css";

// tin

export const RefundPolicy = () => {
  const isMobile = useMobile();
  return (
    <div
      className={
        !isMobile
          ? "cookies-policy-container-flex"
          : "cookies-policy-container-flex-mobile"
      }
    >
      <div className="cookies-policy-container">
        <div className="cookies-policy-content">
          <h1>Refund Policy</h1>

          <h2>ELIGIBILITY FOR REFUND</h2>
          <p>
            Customers can request a full refund within the first week after the
            purchase. The refund will be processed through the same payment
            method used for the transaction.
          </p>

          <h2>REFUND PROCESS</h2>
          <p>
            To initiate a refund request, customers must contact our support
            team within seven days of purchase. Once approved, the amount will
            be refunded within a reasonable processing time. The bank processing
            time for the refund may take between 5 to 10 business days.
          </p>

          <h2>INSTALLMENT PAYMENTS</h2>
          <p>
            If the purchase was made through an installment plan, customers may
            stop future payments if they decide to discontinue the service.
            However, amounts already paid will not be refunded.
          </p>

          <h2>ECONOMIC RESERVES</h2>
          <p>
            Any economic reserve paid in advance is also eligible for a full
            refund upon request.
          </p>
        </div>
      </div>
    </div>
  );
};
