import "./App.css";
import { Banner } from "./components/Banner/Banner";
import { Footer } from "./components/Footer/Footer";
import { Header } from "./components/Header/Header";
import { NavBar } from "./components/NavBar/NavBar";
import LoginPage from "./components/Pages/Login/Login";
import { initializeApp } from "firebase/app";
import { WhoWeAre } from "./components/Pages/WhoWeAre/WhoWeAre";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { firebaseConfig } from "./firebase";
import { Content } from "./components/Pages/Content/Content";
import { LegalDisclaimer } from "./components/Pages/LegalDisclaimer/LegalDisclaimer";
import { PrivacyPolicy } from "./components/Pages/PrivacyPolicy/PrivacyPolicy";
import { CookiesPolicy } from "./components/Pages/CookiesPolicy/CookiesPolicy";
import { RefundPolicy } from "./components/Pages/Reimbursement/ReimbursementPolicy";

function App() {
  initializeApp(firebaseConfig);
  return (
    <div className="App">
      <Header />
      <NavBar />
      <Router>
        <Routes>
          <Route path="/" element={<Banner />} />
          <Route path="/content" element={<Content />} />
          <Route path="/members" element={<LoginPage />} />
          <Route path="/who-we-are" element={<WhoWeAre />} />
          <Route path="/legal-disclaimer" element={<LegalDisclaimer />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/cookies-policy" element={<CookiesPolicy />} />
          <Route path="/reimbursement" element={<RefundPolicy />} />
        </Routes>
      </Router>
      <Footer />
    </div>
  );
}

export default App;
